import { startCase, isEmpty } from 'lodash';
import { getSiteMetadata } from '../../../../../../helpers/WaterDataApiHelper';
import apiConstants from '../../../../../../constants/WaterDataAPIConstants';
import { Event } from '../../../../../../helpers/GAHelper';
import constants from '../../DataDownloadConstants';
import generalConstants from '../../../../../../constants/Constants';
import { formatDate, formatTimeZone } from '../../../../../../helpers/TimeUtils';
import { API_STATUS } from '../../../../../modal/RequestModal';
import moment from 'moment';
import {
  getDataDownloadRequest,
  submitDataDownloadRequest,
  sendNotificationEmail,
} from '../../../../../../helpers/ServicesApiHelper';

// Hydro
export const getHydrometricOptions = async (stationType, selectedStations) => {
  const variables = await extractHydrometricVariables(stationType, selectedStations);
  const formatted = formatHydrometricVariables(variables);

  return formatted;
};

export const processRequestedHydrometric = (
  requestedHydrometric,
  options,
  setHydrometric,
  setRequestedHydrometric,
) => {
  if (requestedHydrometric) {
    let requested = [];
    if (requestedHydrometric.includes(',')) {
      requestedHydrometric.split(',').map(item => {
        const existing = options.find(opt => opt.id === item);
        if (existing) {
          requested.push(existing);
        }
      });
    } else {
      const existing = options.find(opt => opt.id === requestedHydrometric);
      if (existing) {
        requested.push(existing);
      }
    }
    requested && setHydrometric(requested);
    setRequestedHydrometric('');
  }
};

export const reselectHydroByNewOptions = (options, setHydrometric) => {
  setHydrometric(originalHydros => {
    return originalHydros.filter(
      hydroItem => options.findIndex(optionItem => optionItem.id === hydroItem.id) !== -1,
    );
  });
};

const extractHydrometricVariables = async (stationType, selectedStations) => {
  let variables = [];
  if (isEmpty(selectedStations)) return variables;

  if (constants.METRO_DAM === stationType) {
    const ids = selectedStations.map(item => item.id);
    const metaData = await getSiteMetadata(ids.join(','), apiConstants.SITE_TYPE[stationType]);
    metaData.forEach(item => {
      Object.values(item.variablesMonitored).forEach(variable => {
        if (!variables.includes(variable)) {
          variables.push(variable);
        }
      });
    });
  } else if (stationType === constants.METEOROLOGICAL) {
    selectedStations.forEach(item => {
      item?.variables &&
        item.variables.forEach(variable => {
          if (
            (!variables.includes(variable) && item.siteType === constants.METEOROLOGICAL) ||
            (variable === 'Rainfall' && !variables.includes('Rainfall'))
          ) {
            variables.push(variable);
          }
        });
    });
  } else {
    selectedStations.forEach(item => {
      item?.variables &&
        item.variables.forEach(variable => {
          if (!variables.includes(variable)) {
            variables.push(variable);
          }
        });
    });
  }

  return variables;
};

export const formatVariableLabel = variable => {
  const formatExcluded = ['pH'];
  return formatExcluded.includes(variable)
    ? variable
    : variable.includes('.')
    ? variable
        .replaceAll(/([A-Z])/g, ' $1')
        .replaceAll(/[^0-9](?=[0-9])/g, '$& ')
        .replaceAll('. ', '.')
        .replaceAll(' M ', 'M ')
        .trim()
    : startCase(
        variable
          .replace('AHD', '')
          .replace(/([A-Z])/g, ' $1')
          .trim(),
      );
};

const formatHydrometricVariables = variables => {
  return variables.map(item => {
    return {
      id: item,
      name: formatVariableLabel(item),
    };
  });
};

// Submit Button
export const determinBtnType = (
  selectedStations,
  hydrometric,
  dataType,
  interval,
  startDate,
  endDate,
) => {
  if (
    !isEmpty(selectedStations) &&
    !isEmpty(hydrometric) &&
    startDate &&
    !isEmpty(interval) &&
    !isEmpty(dataType) &&
    endDate
  ) {
    const daysDuration = moment.duration(moment().diff(moment(startDate))).as('days');
    const monthDuration = moment.duration(moment(endDate).diff(moment(startDate))).as('months');
    const downloadLimit = constants.DOWNLOAD_LIMIT[interval.id];
    if (daysDuration < 8 && selectedStations.length <= 20 && hydrometric.length < 5) {
      return constants.BUTTONS.get;
    } else if (
      monthDuration > downloadLimit.months ||
      selectedStations.length > downloadLimit.sites ||
      hydrometric.length > downloadLimit.variables
    ) {
      return constants.BUTTONS.request;
    } else {
      return constants.BUTTONS.get;
    }
  } else {
    return constants.BUTTONS.disabled;
  }
};
export const onGetBtnClick = setGenGraph => {
  Event('Download page', 'Light data download', generalConstants.INTERACTION_TYPE.dataDownload);
  setGenGraph(true);
};

export const onRequestBtnClick = (
  selectedStations,
  hydrometric,
  dataType,
  interval,
  startDate,
  endDate,
  setApiStatus,
  user,
) => {
  Event('Download page', 'Heavy data download', generalConstants.INTERACTION_TYPE.dataDownload);
  submitRequest(
    selectedStations,
    hydrometric,
    dataType,
    interval,
    startDate,
    endDate,
    setApiStatus,
    user,
  );
};

const submitRequest = async (
  selectedStations,
  hydrometric,
  dataType,
  interval,
  startDate,
  endDate,
  setApiStatus,
  user,
) => {
  const stationIds = selectedStations.map(item => item.id).join(',');
  const requestList = await getDataDownloadRequest(user.email);
  const from = formatDate(formatTimeZone(startDate).split(',')[0], '', constants.DATE_FORMAT);
  const to = formatDate(formatTimeZone(endDate).split(',')[0], '', constants.DATE_FORMAT);
  const [duplicate, numberOfRequests] = findDuplications(
    requestList,
    stationIds,
    hydrometric,
    dataType,
    interval,
    from,
    to,
  );
  if (isEmpty(duplicate) && numberOfRequests.length < constants.NUM_OF_REQUESTS) {
    setApiStatus(API_STATUS.LOAD);
    const success = await submitDataDownloadRequest(
      user.email,
      stationIds,
      hydrometric.map(item => item.id).join(','),
      interval.id,
      from,
      to,
      dataType.id,
    );
    if (success === true) {
      setApiStatus(API_STATUS.SUCCESS);
      sendNotificationEmail(user.email);
    } else {
      setApiStatus(API_STATUS.FAIL);
    }
  } else {
    if (!isEmpty(duplicate)) {
      setApiStatus(API_STATUS.DUP);
    } else if (numberOfRequests.length === constants.NUM_OF_REQUESTS) {
      setApiStatus(API_STATUS.FORBIDDEN);
    }
  }
};

const findDuplications = (requestList, stationIds, hydrometric, dataType, interval, from, to) => {
  let duplicate = {};
  let numberOfRequests = [];
  if (!isEmpty(requestList)) {
    duplicate = requestList.find(item => {
      const requestGap = moment
        .duration(
          moment(formatDate(moment(), null, `${constants.DATE_FORMAT} hh:mm a`)).diff(
            item.created_datetime,
          ),
        )
        .as('hours');
      return (
        item.station_id === stationIds &&
        item.from_datetime === from &&
        item.to_datetime === to &&
        item.variables === hydrometric.map(item => item.id).join(',') &&
        item.interval === interval.id &&
        item.data_type === dataType.id &&
        requestGap < 24
      );
    });
    numberOfRequests = requestList.filter(item => {
      const requestGap = moment
        .duration(
          moment(formatDate(moment(), null, `${constants.DATE_FORMAT} hh:mm a`)).diff(
            item.created_datetime,
          ),
        )
        .as('hours');
      return requestGap < 24;
    });
  }

  return [duplicate, numberOfRequests];
};
